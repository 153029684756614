<template>
  <div class="container">
    <div class="form-group m-0 col-8 px-0 filter-box">
      <textarea-autosize
        class="form-control"
        placeholder="Фильтр"
        ref="filter_box"
        rows="1"
        v-model="filterQuery"
        @change="updateFilterQuery"
      />
    </div>

    <div class="pt-1">
      <items-selector
        v-model="ownersFilter"
        :items=getAvailableOwners
        button-content="Чьи"
        v-if="getAvailableOwners.length > 1 || ownersFilter.length > 0"
        @items-updated="updateOwnersFilter"
      />
    </div>

    <div class="py-3">
      <spent-tree :data="summary"/>
    </div>

    <div class="col-8 pt-0 px-0 pb-5 events-list-wrapper">
      <div class="events-list">
        <event-list-item
          v-for="event in eventsPage"
          :key="event.id"
          :event="event"
          :filter-query="filterQuery"
          :selected-events="bulkModeEventsSelected"
          @event-selected="eventSelected"
          @event-deselected="eventDeselected"
          @category-selected-successfully="updateSummary"
        />
        <p
          v-if="!eventsLoading && eventsPage.length === 0"
          class="text-muted text-center">Ничего нет 🤷‍♂️</p>
      </div>

      <div v-if="hasMoreEvents" class="d-flex justify-content-center bg-light mt-3">
        <button
          type="button"
          class="btn btn-link py-2"
          @click="$store.dispatch('events/fetchNext')"
        >Ещё..</button>
      </div>

    </div>

    <div
      class="d-flex flex-row fixed-bottom shadow border-top bg-white px-5 py-2"
      :class="{'imp-hide': !bulkMode}"
    >
      <div style="padding-top: 2px">
        Выбрано событий: {{ Object.keys(bulkModeEventsSelected).length }}
      </div>
      <span class="text-muted px-2">•</span>
      <event-category-selector
        :event-owner-id="currentUser.id"
        :events="Object.values(bulkModeEventsSelected)"
        :selected-category="null"
        @category-selected="deselectAllEvents"
        @category-selected-successfully="updateSummary"
        :noCategoryText="'Установить категорию'"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpentTree from '@/components/views/events/SpentTree'
import TextareaAutosize from '@/components/common/TextareaAutosize'
import EventListItem from '@/components/views/events/EventListItem'
import EventCategorySelector from '@/components/views/events/EventCategorySelector.vue'
import ItemsSelector from '@/components/common/ItemsSelector'

export default {
  data () {
    return {
      filterQuery: this.$route.query.filter || '',
      ownersFilter: this.parseQueryArray(this.$route.query.owners || ''),
      bulkModeEventsSelected: {}
    }
  },
  computed: {
    ...mapGetters({
      eventsPage: 'events/getPage',
      hasMoreEvents: 'events/hasMoreEvents',
      summary: 'events/getSummary',
      eventsLoading: 'events/getEventsLoading',
      currentUser: 'auth/getCurrentUser'
    }),
    bulkMode () {
      return Object.keys(this.bulkModeEventsSelected).length > 0
    },
    getAvailableOwners () {
      const result = [{
        id: this.currentUser.id,
        name: this.currentUser.first_name
      }]
      this.$store.getters['share/getAvailableOwners'].forEach(owner => {
        result.push({ id: owner.id, name: owner.first_name })
      })
      return result
    }
  },
  methods: {
    updateEvents () {
      this.$store.dispatch('events/fetch', { filter: this.filterQuery, owners: this.ownersFilter })
      this.$store.dispatch('events/fetchSummary', { filter: this.filterQuery, owners: this.ownersFilter })
    },
    updateFilterQuery (value) {
      if (this.$route.query.filter !== this.filterQuery) {
        this.$router.push({ query: { ...this.$route.query, filter: this.filterQuery } })
      }
    },
    updateOwnersFilter () {
      if (this.ownersFilter.length === 0 && this.getAvailableOwners.length > 1) {
        this.ownersFilter = [this.currentUser.id]
      }
      const joined = this.ownersFilter.join(',')
      if (this.$route.query.owners !== joined) {
        this.$router.push({ query: { ...this.$route.query, owners: joined } })
        return true
      }
      return false
    },
    updateSummary () {
      this.$store.dispatch('events/fetchSummary', { filter: this.filterQuery, owners: this.ownersFilter })
    },
    eventSelected (event) {
      this.$set(this.bulkModeEventsSelected, event.id, event)
    },
    eventDeselected (event) {
      this.$delete(this.bulkModeEventsSelected, event.id)
    },
    deselectAllEvents () {
      for (const eventId in this.bulkModeEventsSelected) {
        this.$delete(this.bulkModeEventsSelected, eventId)
      }
    },
    parseQueryArray(queryString) {
      return queryString ? queryString.split(',') : []
    }
  },
  watch: {
    '$route.query.owners'(newUrlOwners) {
      this.ownersFilter = this.parseQueryArray(newUrlOwners || '')
    },
    '$route.query.filter'(newUrlFilter) {
      this.filterQuery = newUrlFilter || ''
    },
    '$route.query'() {
      if (!this.updateOwnersFilter()) {
        this.updateEvents()
      }
    }
  },
  created () {
    this.updateEvents()
    const self = this
    this.$store.dispatch('share/fetchavailableOwners').then(() => {
      self.updateOwnersFilter()
    })
  },
  components: {
    SpentTree,
    TextareaAutosize,
    EventListItem,
    EventCategorySelector,
    ItemsSelector
  }
}
</script>

<style lang="scss" scoped>
.imp-hide {
  display: none !important;
}

@media (max-width: 991.98px) {
  .events-list-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .filter-box {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.events-list>div:last-child {
  border-bottom: 1px solid #dee2e6!important;
  border-bottom-left-radius: .25rem!important;
  border-bottom-right-radius: .25rem!important;
}
.events-list>div:first-child {
  border-top-left-radius: .25rem!important;
  border-top-right-radius: .25rem!important;
}
</style>
