<template>
  <div class="items-selector">
    <b-dropdown
      no-caret
      variant="link"
      left
      ref="items-selector-dropdown"
      :lazy="true"
    >
      <template #button-content>
        <div class="rounded px-1 items-selector-button">{{ buttonContent }}{{ selectedContent }}</div>
      </template>
      <template class="p-0">
        <ul class="select-items-wrapper">
          <li class="select-items-item" v-for="item in items" :key="item.id">
            <div>
              <a
                href="#"
                class="item d-flex justify-content-between align-items-center px-2"
                @click.prevent="selectItem(item.id)"
                ><span>{{ item.name }}</span>
                <font-awesome-icon
                  icon="check"
                  class="text-success"
                  style="font-size: 0.8rem"
                  v-if="value.includes(item.id)"
                />
              </a>
            </div>
          </li>
      </ul>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'ItemsSelector',
  props: {
    buttonContent: {
      type: String,
      default: 'Select Items'
    },
    items: {
      type: Array,
      required: true
    },
    value: { // selected ids
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    selectItem(itemId) {
      const index = this.value.indexOf(itemId)
      if (index !== -1) {
        this.value.splice(index, 1)
      } else {
        this.value.push(itemId)
      }
      this.$emit('input', this.value)
      this.$emit('items-updated')
    },
    nameById(itemId) {
      const item = this.items.find(el => el.id === itemId)
      if (item) {
        return item.name
      }
      return null
    }
  },
  computed: {
    selectedContent() {
      if (this.value.length === 0) {
        return ''
      }
      let out = ''
      for (let i = 0; i < this.value.length; ++i) {
        if (i > 0) {
          out += ','
        }
        out += this.nameById(this.value[i])
        if (i === 1) {
          // not more then 2 items
          break
        }
      }
      if (this.value.length > 2) {
        out += ', ..'
      }
      return ': ' + out
    }
  }
}
</script>

<style lang="scss">
.items-selector {
  display: contents;

  .btn {
    padding: 0 !important;
    border: none;
  }

  a,
  .btn-link {
    text-decoration: none;
    color:#000;
  }

  .btn-link:hover {
    text-decoration: none;
    color:#000;
  }

  .dropdown-menu {
    padding: 0;
  }
}
.select-items-wrapper {
  margin: 0;
  padding: 0;
}
.select-items-items {
  overflow-y: scroll;
  max-height: 300px;
}
.form-control:focus {
  border-color: inherit;
}

.select-items-item {
  list-style: none;
  position: relative;

  .item {
    display: block;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    color: #000;
    font-weight: 300;
  }

  &:hover {
    text-decoration: none;
    background-color: #e9ecef;
  }
}

.items-selector-button {
  background-color: #e9ecef82;
  font-size: 0.9rem;

  &:hover {
    background-color: #dee2e6;
  }
}
</style>
